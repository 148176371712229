@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
}


@layer utilities {

  .btn_blue { 
    @apply xl:py-3 py-4 px-[7.5rem]  lg:px-14  text-white-10 bg-blue-20 hover:bg-slate-400/25 cursor-pointer transition ease-out text-center active:outline focus:outline-gray-10
  }

  .btn{ 
    @apply text-center my-1 rounded-full cursor-pointer  duration-300 transition-all ease-in-out outline-2 hover:outline 
  }
  .btn_greey{ 
    @apply text-center my-1 rounded-full cursor-pointer  duration-300 transition-all ease-in-out outline-2 hover:outline bg-slate-300 border border-slate-400 py-3 px-10 hover:outline-slate-500 hover:bg-slate-500/90 shadow 
  }
  .btn_slate { 
    @apply   bg-slate-400 text-gray-40 hover:text-white-10 cursor-pointer  duration-300 transition-all ease-in-out outline-2 hover:outline hover:outline-slate-500 hover:bg-slate-400/70
  }
  /* .btn_dark_green { 
    @apply  bg-green-90 px-8 py-4 text-white transition-all hover:bg-black
  }
  .btn_dark_green_outline  { 
    @apply border-gray-20 bg-green-90 px-8 py-5 text-white
  } */
  .transition_duration{
    @apply  duration-700;

  }

  .max-container {
    @apply mx-auto max-w-[1440px];
  }

  .padding-container {
    @apply px-6 lg:px-20 2xl:px-0 py-4;
  }

  .flexCenter {
    @apply flex items-center justify-center;
  }

  .flexBetween {
    @apply md:flex items-center justify-between;
  }

  .flexStart {
    @apply flex items-center justify-start;
  }

  .flexEnd {
    @apply flex items-center justify-end;
  }
  .transitionAll75 {
    @apply transition-all  ease-linear delay-100;
  }
}


:root {
  /* --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255; */
}

@media (prefers-color-scheme: dark) {
  :root {
    /* --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0; */
  }
}

body {
  /* color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb)); */
}
